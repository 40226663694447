.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.intro {
	margin-top: 25px;
    margin-left: 50px;
    text-align: left;
    font-family: Verdana;
    font-size: 16px;
}

.title {
    text-align: center;
    font-family: Verdana;
    font-size: 50px;
}

.subTitle {
    text-align: center;
    font-family: Verdana;
    font-size: 25px;
}

.textBody {
margin-bottom: -48px;

}

.mike {
  display: block;
  margin-left: auto;
  margin-right: auto;
}




	.post {
	margin-top: 25px;
    text-align: left;
    font-family: Verdana;
    font-size: 16px;
	}

		.chapterNumber {
		  text-align: center;
    	font-weight: bold;
    	}

    	.chapterTitle {
    	  text-align: center;
        margin-top: 50px;
        }

    Nav {
  font-size: 20px;
  font-family:Century Gothic;
      display: block;
        position: sticky;
        width: 100%;


    }

    .navbar{
    background-color: #66CDAA;
    margin-bottom: 40px;
    }

    Navigation {

    }





        .navbarToggle {
      font-size: 20px;
      width:30%;
      margin-left: 10%;

        }


.blogWord {
color: black;
font-size: 100%;
text-align:center;

}

.bookWord {
color: black;
font-size: 100%;
text-align:center;

}

.blogLinkOne {
color: black;
font-size: 150%;
             display: block;
                      margin-left:auto;
                       margin-right:auto;

}

.bookLinkOne {
color: black;
font-size: 150%;
             display: block;
                      margin-left:auto;
                       margin-right:auto;

}

Button {
margin-right: auto;
}

