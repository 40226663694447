body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.staff {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 800px;
}

.clef {
display: block;
margin-left: auto;
margin-right: auto;
width: 200px;
height: 200px;
}

.bass {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 280px;
  height: 300px;
}

.barline {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 200px;
  height: 200px;
}

.measure {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 800px;
  height: 200px;
}

.time {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 250px;
  height: 270px;
}

.quarter {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 250px;
  height: 270px;
}

.four {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 800px;
  height: 200px;
}

.quarterRest {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 265px;
  height: 265px;
}

.fourRest {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 800px;
  height: 200px;
}

.repeat {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 200px;
  height: 200px;
}


.fourMeasure {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 800px;
  height: 200px;
}


.hiHat {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 200px;
  height: 250px;
}

.hiHatAndSnare {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 1000px;
}

.hiHatAndBass {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 1000px;
}

.phrase {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 1000px;

}

.NumOne {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 1000px;

}

.NumTwo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 1000px;

}

.NumberOne {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 1000px;

}

.NumberTwo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 1000px;

}

.NumberThree {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 1000px;

}

.eighthNote {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 225px;
  height: 225px;

}

.twoConnect {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 150px;

}

.fourConnect {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 150px;

}

.quarterEighth {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 1000px;

}

.eighthCount {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 1000px;

}

.Core {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 1000px;
  height: 200px;

}

.Tom {
align: Center;
}

